@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: #000000;
  z-index: 10;
}

header section {
  max-width: 1300px;
  margin: auto;
  padding: 20px;
  background: #ffd800;
}

@media (max-width: 1515px) {
  header section {
    max-width: 100%;
  }
}

header section .logo {
  transform: translateX(-87px);
}

header section a {
  float: right;
  padding: 3px 0;
}

* {
  outline: none;
}

.slider-wrapper button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #ffd800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
}
.slider-wrapper button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: calc(100vh - 65px);
  overflow: hidden;
}

.slide {
  height: calc(100vh - 65px);
  background-size: cover !important;
}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url('../assests/icons/next.png') no-repeat center center / 50px;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .previousButton,
  .nextButton {
    width: 40px;
    height: 40px;
    background: url('../assests/icons/next.png') no-repeat center center / 40px;
  }
}

.previousButton {
  left: 2%;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.nextButton {
  right: 2%;
}

.previousButton:hover,
.nextButton:hover {
  background: url('../assests/icons/next.png') no-repeat center center / 50px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  box-sizing: border-box;
  position: absolute;
  width: 56.25rem;
}

.inner-heading-block {
  position: relative;
  display: inline-block;
}

.slider-content .inner-bottom:before {
  content: '';
  display: inline-block;
  width: 110%;
  height: 120%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  background-color: #0000004d;
  z-index: -1;
}

.inner-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;
  margin: 0 auto;
  text-transform: capitalize;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 1.4rem;
  margin: 25px 100px 0px 100px;
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}
.scroll-down-block {
  margin-top: -50px;
}
.scroll-down {
  display: flex;
  width: 100%;
}
.scroll-down-box {
  align-self: flex-end;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin: 0 auto;
  transform-origin: bottom;
}
.scroll-down-box svg {
  color: #cccccc;
  cursor: pointer;
}
.bounce-1 {
  animation-name: bounce-1;
  animation-timing-function: linear;
}
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1280px) {
  .slider-content .inner {
    width: 43rem;
  }

  .slider-content .inner h1 {
    font-size: 32px;
  }

  .slider-content .inner p {
    font-size: 1.3rem;
  }
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
}

@media (max-width: 600px) {
  .slider-content .inner h1 {
    font-size: 2rem;
  }

  .slider-content .inner p {
    font-size: 1.3rem;
  }

  .slider-content .inner {
    width: 25rem;
  }
}
