.container-contactus h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  color: #000000;
  text-align: left;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.contactUsForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 1rem;
}

.form-group-contactus {
  flex: 1 0 50%;
  margin-bottom: 10px;
}

.message-container-contactus {
  flex: 0 1 100%;
}

.input-contactus {
  width: 99%;
  margin-right: 1%;
  letter-spacing: 0.075em;
  color: #777;
  font-size: 15px;
  padding: 16px 25px;
  border: 1px solid #e1e1e1;
  outline: none;
}

.input-error-contactus {
  width: 99%;
  margin-right: 1%;
  letter-spacing: 0.075em;
  color: #777;
  font-size: 15px;
  padding: 16px 25px;
  border: 1px solid #db5461;
  outline: none;
}

.message-textarea-contactus {
  width: 100%;
  height: 230px;
}

.contact-main-block {
  margin-bottom: 50px;
  margin-top: 50px;
}
.invalid-feedback-contactus {
  font-size: 12px;
  text-align: left;
  color: #db5461;
}

@media (max-width: 600px) {
  .contact-main-block {
    margin-bottom: 0px;
  }
}
