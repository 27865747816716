.home-services {
  background-color: #01669a;
  padding: 2rem 0;
}

.primary-service__container {
  font-size: 1.5rem;
  text-align: center;
  padding: 0 1rem;
}

@media (max-width: 600px) {
  .section-primary-services .row div:nth-child(-n + 2) {
    margin-bottom: 2rem;
  }
}

.primary-service__icon {
  font-size: 4rem;
  font-weight: bold;
  color: white;
  display: inline-block;
}

.primary-service__container img {
  margin: 0 2.2rem;
  object-fit: contain;
  height: 3.8rem;
  margin-bottom: 1rem;
}

.section-heading-secondary {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: white;
  margin-bottom: 0.2rem;
}

.primary-service__container span {
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: white;
}
