.director-message-quote {
  color: black !important;
  font-size: 1.6rem !important;
  line-height: 2rem !important;
  margin: 2.2rem 0 2.2rem 0;
}

.product-details-block-info p {
  font-size: 1.3rem;
  color: #686767;
}

.director-sign-off {
  margin-bottom: 3rem;
}

.director-sign-off p {
  color: black;
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
  margin-top: 0;
}
