.contact-main-block {
  border-right: 1px solid #ddd;
  padding-right: 25px;
}
.contact .row {
  align-items: flex-start;
}
.contact-get-in-touch {
  padding-top: 40px;
}
.contact-get-in-touch h2 {
  margin-bottom: 35px;
}

.number-blcok span,
.office-blcok span,
.email-blcok span {
  font-weight: 600;
}

@media (max-width: 600px) {
  .contact-get-in-touch {
    padding-top: 10px;
  }
}
