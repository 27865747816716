.reach-container {
  padding: 5rem 0;
}

.reach-ul-container h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.reach-ul-container {
  background: linear-gradient(
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7)
    ),
    url('../assests/map.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.reach-categories ul {
  columns: 4;
  -webkit-columns: 4;
  -moz-columns: 4;
}

.reach-categories ul li {
  font-family: 'Roboto';
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 600px) {
  .reach-container {
    margin: 0 2rem;
  }

  .reach-categories ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
