.nav {
  margin: 0 auto !important;
}
.navbar {
  padding: 0 1rem !important;
}
.navbar-brand {
  padding: 0;
}
.nav-link {
  padding: 7px 1rem !important;
}

@media (max-width: 767px) {
  .header-phonemenu {
    display: block !important;
    height: auto;
    padding: 0 !important;
    border: none !important;
  }
  .header-contact {
    float: none !important;
    padding-right: 0 !important;
    display: block;
  }
  .header-contact .header-phone,
  .header-contact .header-address {
    display: block !important;
    padding: 0;
  }
  .header-main .navbar-collapse {
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff;
    top: 86px;
  }
  .nav__menu-item {
    display: block;
  }
  .navbar-toggler {
    margin-top: 20px;
  }
  .header-address {
    width: 160px;
  }
  .header-address img {
    float: left;
  }
}
