.peoplegallery__container {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  align-items: flex-start;
}

.peoplegallery__child {
  position: relative;
  margin-bottom: 3rem;
  margin-right: 2rem;
}

.peoplegallery__image {
  height: 300px;
  width: 240px;
  object-fit: cover;
}

@media (max-width: 1280px) {
  .peoplegallery__image {
    height: 250px;
    width: 200px;
  }
}

.peoplegallery__child__block {
  position: absolute;
  height: 100px;
  width: 100%;
  bottom: -10%;
  background-color: #25313d;
  text-align: center;
  color: white;
}

.peoplegallery__child__block h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
}

.peoplegallery__child__block p {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  margin-top: 0.5rem;
  letter-spacing: 0.05rem;
}

.peoplegallery__child__block__details {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.peoplegallery__child__block__line {
  width: 50%;
  height: 2px;
  border-bottom: 1px solid #ee7d1f;
  margin-left: 25%;
}

/* ------- overlay --------- */

.people-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 110%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #3d4547;
}

.peoplegallery__child:hover .people-overlay {
  opacity: 1;
}

.people-overlay-text {
  color: white;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
