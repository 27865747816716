.client-header h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #4d4d4d;
  text-transform: uppercase;
}

.middel-text p {
  font-family: 'Roboto';
  font-size: 1.2rem;
  font-weight: 300;
  padding: 0 10rem 0 10rem;
  margin: 2rem 0 2rem 0;
  font-weight: bold;
  color: #b3b3b3;
}

@media (max-width: 600px) {
  .middel-text p {
    padding: 0;
  }
}

.client-logo {
  padding-top: 2.2rem;
  padding-bottom: 2.2rem;
}
.first-logo {
  margin-bottom: 1.5rem;
}
.client-logo img {
  margin: 0 2.2rem;
  object-fit: contain;
  width: 9.375rem;
  height: 6.25rem;
}

@media (max-width: 1280px) {
  .client-logo img {
    width: 8.375rem;
    height: 5.25rem;
  }
}
