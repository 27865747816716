/* ---------- Banner ----------*/

.about-us__section-banner img {
  object-fit: cover;
  height: 600px;
  width: 100%;
}

/* ---------- who we are ----------*/

.about-us__section-main {
  background-color: #01669a;
}

.section-main-block {
  align-items: flex-start;
}

/* ---------- 4 categories ----------*/

.about-us__section-details {
  background-color: white;
}

.about-us__section-details .container .row {
  min-height: 250px;
  position: relative;
  margin-bottom: 3rem;
}

.about-us-details-img {
  min-height: 250px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
}

.details-text-block {
  margin-left: 3rem;
  margin-right: 4rem;
}

@media (max-width: 991px) {
  .about-us__section-details .container .row {
    min-height: 0px;
  }

  .about-us-details-img {
    min-height: 0px;
  }

  .details-text-block {
    margin-left: 0;
    margin-right: 0;
  }
}
