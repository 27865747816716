.footer {
  background: #40495a;
  padding: 2rem;
}

.footer-row {
  align-items: flex-start;
}

@media (max-width: 600px) {
  .footer-row > div {
    margin-bottom: 2.5rem;
  }
}

.footer ul {
  margin: 0;
  padding: 0;
}

.footer a,
.footer p {
  color: #fff;
}

.footer-group--title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem;
  color: #fff;
  text-transform: capitalize;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.footer-links ul li {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: white;
}

.footer-contactus svg {
  color: #fff;
}

.footer-contactus-group {
  display: flex;
}

.footer-contactus-group span {
  background: #535c6b;
  padding: 4px 7px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 14px;
  object-fit: contain;
  align-self: flex-start;
}

.footer-contactus-group p {
  margin-left: 10px;
  margin-top: 4px;
  flex: 1 1 auto;
}

.footer-cert-img {
  object-fit: contain;
  width: 100%;
  margin-bottom: 0.5rem;
}

.footer-iso-img {
  object-fit: contain;
  width: 100%;
  margin-bottom: 0.5rem;
}

.footer-socialmedia {
  margin-top: 20px;
}

.footer-socialmedia a {
  background: #535c6b;
  padding: 10px 12px;
  margin-right: 5px;
}

.db-certificate-link {
  margin: auto;
  margin-top: 10px;
}

.db-certificate-link p {
  font-size: 1rem;
}
