.buss-unit-product-flex-container {
  display: flex;
}

.product-info-hover {
  /* height: 100% !important; */
  min-height: 30rem;
}

.home-business-unit .home-product-card-0 .card-body {
  background: #01669a !important;
}

.card-img-top {
  border-radius: 0;
  object-fit: cover;
}

.home-hover-categories ul {
  margin: 0;
  padding: 0 0 0 1.43rem;
}
.home-hover-categories {
  min-height: 23.25rem;
}
/*---- hover effect-----*/

.home-product-card {
  position: relative;
  height: 31.25rem;
  border: none;
  margin-bottom: 4rem;
}

@media (max-width: 600px) {
  .home-product-card {
    max-width: 26rem;
    margin: auto auto 2rem auto;
  }
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 15.625rem;
  transition: all 0.4s ease;
}

.overlay-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6rem;
}

.overlay-title a {
  color: white;
  text-transform: capitalize;
}

.overlay-des-2 {
  font-family: 'Roboto';
  font-size: 1.2rem;
  font-weight: 200;
  color: #cccccc;
}

.overlay-text {
  font-family: 'Roboto';
  font-size: 1.2rem;
  font-weight: 200;
  transition: all 0.4s ease-out;
}

.home-hover-categories ul li a {
  color: #cccccc;
  text-decoration: none;
  text-transform: capitalize;
}

.home-hover-categories ul li a:hover {
  color: #fff;
}

.home-product-card:hover .overlay {
  height: 100%;
}
.home-product-card:hover .overlay-des {
  opacity: 0;
  display: none;
}
.overlay-text {
  opacity: 0;
  display: block;
}
.home-product-card:hover .overlay-text {
  opacity: 100;
}
/*---- hover effect-----*/
.product-read-more a {
  color: #fff;
  text-decoration: none;
}
