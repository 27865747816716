.slider-section-heading {
  position: relative;
  margin-bottom: 3rem;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
}

.slider-section-heading::after {
  display: block;
  content: '';
  width: 200px;
  height: 3px;
  background: white;
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.slider-content .inner p {
  margin: 0;
  margin-top: 2rem;
  text-align: left;
}

.section-heading-primary {
  position: relative;
  margin-bottom: 3rem;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.section-heading-primary::after {
  display: block;
  content: '';
  width: 70px;
  height: 3px;
  background: #01669a;
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.section-heading-primary-light {
  position: relative;
  margin-bottom: 3rem;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

.section-heading-primary-light::after {
  display: block;
  content: '';
  width: 70px;
  height: 3px;
  background: #ee7d1f;
  position: absolute;
  bottom: -1rem;
  left: 0;
}

.heading-primary {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
}

.heading-secondary {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.2rem;
  color: black;
  margin-bottom: 1.5rem;
  text-transform: capitalize;
  font-weight: 500;
}

.para {
  font-family: 'Roboto' !important;
  margin-bottom: 1rem !important;
}

.para--dark-gray {
  color: #585858 !important;
}

.para--light-gray {
  color: #646575b6 !important;
}

.para--white {
  color: #fff !important;
}

.para--small {
  font-size: 1rem !important;
}

.para--medium {
  font-size: 1.1rem !important;
}

.para--large {
  font-size: 1.2rem !important;
}

.remove-bot-margin {
  margin-bottom: 0 !important;
}
