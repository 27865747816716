html {
  font-size: 16px;
}

@media (max-width: 1280px) {
  html {
    font-size: 12px;
  }

  .container {
    max-width: 960px;
  }
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.styles_modal__gNwvD {
  max-width: 850px !important;
}

@media (max-width: 600px) {
  .styles_modal__gNwvD {
    max-width: 400px !important;
  }
}
