.product-details-block-title h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.4rem;
  color: #000000;
  text-transform: capitalize;
}

.product-details-block-info p {
  font-family: 'Roboto';
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.08rem;
  color: #666666;
}

.product-details-block {
  margin-top: 40px;
}
.product-details-block .row {
  align-items: flex-start;
}
.product-category-main-block {
  border-left: 1px solid #ddd;
}

.product-category-title {
  margin-left: 35px;
}
.product-category-title h2 {
  font-size: 25px;
  text-transform: capitalize;
}
.product-category li {
  list-style: none;
}
.product-category li a {
  /* display: blpck !important; */
  border-bottom: 1px solid #ddd;
  padding: 10px 0 !important;
  position: relative;
  text-transform: capitalize;
  letter-spacing: 0.7px;
}
.product-category a.active::before {
  width: 47px;
}
.product-category a:hover:not(.active)::before {
  width: 100%;
}
.product-category a::before {
  position: absolute;
  content: '';
  bottom: -1px;
  left: 0;
  height: 3px;
  width: 0;
  background: #f9a84c;
  transition: all 0.3s ease;
}
.product-card {
  margin-bottom: 40px;
  border: none;
}
.product-card .card-body {
  text-align: center;
  padding: 0;
  -webkit-transition: margin-top 0.3s;

  /* margin-top: -10px; */
}
.product-card-title {
  display: inline;
  padding: 10px 30px;
  background: white;
  /* box-shadow: 5px 10px #888888; */
}

.zoom {
  /* padding: 50px; */
  /* background-color: green; */
  transition: transform 0.2s; /* Animation */
}

.zoom:hover ~ .card-body {
  margin-top: 20px !important;
}
.international-product {
  height: 150px;
  overflow: hidden;
  border: none;
  border-radius: 0;
}

.molybdenum-product {
  height: 380px;
  overflow: hidden;
  border: none;
  border-radius: 0;
}

.product-data {
  position: relative;
  margin-bottom: 70px;
}
.international-product:hover img {
  transform: scale(1.2);
}

.product-card-block {
  margin-top: 40px;
}
.product-data .product-card-title {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -37px;
  background-color: #01669a;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  letter-spacing: 0.5px;
}
.banner-top .card-img {
  border-radius: 0;
  object-fit: cover;
}
.banner-top .card {
  border: none;
}

@media (max-width: 600px) {
  .product-category-main-block {
    border-left: none;
  }

  .product-category-title {
    margin-left: 5px;
  }
}
