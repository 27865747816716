.home-business-right-block {
  background-color: lightgray;
}
.home-business-headers {
  margin-bottom: 40px;
}

@media (max-width: 600px) {
  .home-business-headers {
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

.home-business-unit {
  padding-top: 3rem;
}

.unit-left-header {
  margin-left: 15px;
}

.unit-left-header h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
}

.unit-left-header span {
  font-family: 'Roboto';
  font-size: 1.4rem;
  color: #666666;
}

.unit-right-header {
  float: right;
}

@media (max-width: 600px) {
  .unit-right-header {
    margin-top: 1.5rem;
    float: none;
  }
}

.card-body {
  background: #666666;
  color: #fff;
}
.product-read-more {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}
.product-read-more p {
  margin-bottom: 0;
}
.home-business-unit {
  position: relative;
}
.indsutris-block {
  position: relative;
}
.home-business-unit:before {
  content: '';
  width: 34%;
  height: 100%;
  background-color: #e6e6e6;
  top: 0px;
  right: 0;
  position: absolute;
  display: inline-block;
  z-index: -10000;
}
@media only screen and (min-width: 1279px) and (max-width: 1380px) {
  .home-business-unit:before {
    width: 27%;
  }
}

.btn-large,
.btn-medium,
.btn-small {
  font-family: 'Open Sans', sans-serif;
  border-radius: 0;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.1s;
  background-color: #29abe2;
  color: #fff;
  border: none;
}

.btn-large {
  font-size: 1.2rem;
  padding: 1.5rem 3rem;
}

.btn-medium {
  font-size: 1rem;
  padding: 1.1rem 2.3rem;
}

.btn-small {
  font-size: 0.8rem;
  padding: 0.7rem 1.5rem;
}

.btn-large:hover,
.btn-medium:hover,
.btn-small:hover {
  transform: translateY(-3px);
  background-color: #01669a;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.btn-large:active,
.btn-medium:active,
.btn-small:active {
  transform: translateY(-1px);
  background-color: #01669a;
}

.btn-large:focus,
.btn-medium:focus,
.btn-small:focus {
  box-shadow: none;
}

@media (max-width: 1280px) {
  .home-business-section .container {
    max-width: 960px;
  }
}
