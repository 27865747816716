/*.accordion__button::before {
  contain: '';
  border: none;
  height: 0;
  width: 0;
  margin: 0;
}
.accordion__button::after {
  content: '';
  height: 10px;
  width: 10px;
  float: right;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-135deg);
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
  transform: rotate(45deg);
}
*/
.position-block .right-side-label {
  padding-left: 30px;
}

.accordion {
  width: 100%;
}

.panel__label {
  position: relative;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 15px 60px 15px 0;
  font-weight: 500;
  font-size: 17px;
  font-family: inherit;
  transition: color 0.2s linear;
  cursor: pointer;
}
.panel__label:focus {
  outline: none;
}
/* .panel__label:after,
.panel__label:before {
  content: '';
  position: absolute;
  right: 25px;
  top: 50%;
  width: 22px;
  height: 2px;
  margin-top: -2px;
  background-color: #372717;
} */

.panel__label:before {
  content: '';
  position: absolute;
  right: 0;
  height: 10px;
  width: 10px;
  float: right;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
  top: 25px;
}
/* .panel__label:before {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1),
    -webkit-transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
} */
.panel[aria-expanded='true'] .panel__content {
  opacity: 1;
}
.panel[aria-expanded='true'] .panel__label {
  /* color: #957029; */
}
.panel[aria-expanded='true'] .panel__label:before {
  -webkit-transform: rotate(0deg);
  transform: rotate(-135deg);
}
.panel__inner {
  overflow: hidden;
  will-change: height;
  transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.panel__content {
  margin: 5px 0 25px;
  font-size: 14px;
  color: #756658;
  opacity: 0;
  transition: opacity 0.3s linear 0.18s;
}
.panel {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
}
.panel:not(:last-child) {
  margin-bottom: 3px;
}
.career-details {
  width: 50%;
  margin: 50px auto;
}
.career-details p {
  font-size: 17px;
  font-weight: 600;
  position: relative;
  line-height: 30px;
  padding-bottom: 40px;
}
.career-details p::before {
  content: '';
  display: inline-block;
  border-bottom: 1px solid rgba(205, 214, 218, 0.301);
  width: 85%;
  height: 8rem;
  position: absolute;
  left: 1.3rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}
.panel__content {
  background: #f7f7f7;
  padding: 40px 50px;
}
.panel__content ul {
  padding: 0 0 0 20px;
}
.panel__content span {
  margin-bottom: 15px;
  display: block;
  font-size: 18px;
}
.panel__content ul li {
  padding-bottom: 10px;
  font-size: 16px;
}
.position-block {
  padding-bottom: 1rem;
}

.career-not-found-block {
  padding-bottom: 2rem;
}
