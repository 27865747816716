.press-release-list {
  list-style-type: none;
  margin-bottom: 2rem;
  margin-left: 2rem;
  padding-inline-start: 0px;
}

.press-release-link {
  font-size: 1.5rem;
  text-transform: capitalize;
  margin-bottom: 0.8rem;
}

.press-release-link a {
  color: black;
}
