.product-details-block-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 3rem;
  color: #000000;
  text-transform: capitalize;
}

.product-details-block-info {
  font-size: 1.5rem;
  line-height: 2.5rem;
  letter-spacing: 0.05rem;
  color: #666666;
  margin-bottom: 2rem;
}

.product-details-block-info .details-block .details-block-heading {
  color: #585858;
  text-transform: capitalize;
  font-weight: bold;
}

.product-details-block-info img {
  float: right;
  margin: 0 25px 10px 25px;
  width: 300px;
  height: 200px;
  object-fit: cover;
}

.product-btns {
  margin-bottom: 2rem;
}

.product-btns > * {
  display: inline-block;
}

.brochure-button {
  margin-left: 30px;
}

.brochure-icon {
  margin-right: 5px;
}

.product-btns a {
  color: white;
  text-decoration: none;
}

.product-btns a:hover {
  color: white;
}

@media (max-width: 600px) {
  .brochure-button {
    margin-left: 0px;
    margin-bottom: 1rem;
  }
}
