.navbar-right a {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1rem;
}
.submenu-containe a {
  font-size: 0.875rem;
}
.navBar-sticky {
  /* background-color: #fff;
  position: fixed; 
  width: 100%;
  top: 0; 
  z-index: 99;
  margin-bottom: 10px; */
}
.navBar-fix {
  /* z-index: 99;
  background-color: #fff;
  position: sticky; 
  width: 100%;
  top: 0; 
  z-index: 99; */
}

#navbar #logo {
  font-size: 2rem;
  font-weight: bold;
  transition: 0.4s;
}
#navbar {
  border-bottom: 1px solid #ddd;
}

.navbar-right {
  float: right;
  width: 100%;
}
.logo {
  width: 100%;
  text-align: center;
}
.logo img {
  height: 5.2rem;
}
#navbar .logo a {
  float: none;
  text-align: center;
}

.header-contact .header-phone,
.header-contact .header-address {
  display: flex;
  padding-left: 1rem;
}
.header-contact {
  display: flex;
}
.header-contact {
  float: right;
  padding-right: 2.625rem;
}

.header-phone svg {
  float: left;
}
.header-address svg {
  float: left;
}
.header-contact p {
  font-size: 0.75rem;
  text-align: left;
  margin-bottom: 0;
}
.bottom-header {
  padding-right: 1.25rem;
  padding-top: 1.2rem;
}
/* .bottom-header ul li {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  float: left;
} */
.bottom-header ul li svg {
  margin-right: 0.625rem;
}

.header-phonemenu {
  font-weight: 300;
  padding-left: 6.25rem;
  padding-right: 6.25rem;
  height: 2.18rem;
}

.header-phonemenu {
  display: flex;
  font-size: 0.8125rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.5px;
  height: 3.125rem;
  text-align: center;
  color: #394a58;
  padding-top: 0.75rem;
  border-bottom: 1px solid #ececec;
  background-color: #ffffff;
}
.header-main {
  width: 100%;
}

.flag-icon {
  margin-right: 5px;
  margin-top: -5px;
}
/* new hover css */
.menu-container {
  text-align: center;
  margin-left: -10;
}
nav ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.nav {
  display: inline-block;
  margin: 1.25rem auto 0;
  color: #fff;
  text-align: left;
}
.nav .nav-span {
  display: block;
  /* padding: 0 16px; */
  line-height: inherit;
  cursor: pointer;
  /* padding-right: 0.5rem;
  padding-left: 0.5rem; */
  /* padding-right: 1px;
  padding-left: 1px; */
}
.nav__submenu-item a:link {
  display: block;
  padding-left: 1rem !important;
}

.nav__submenu-item .nav-span a:hover {
  color: rgb(192, 189, 189) !important;
}

.nav__submenu-item .nav-span a:first {
  color: black !important;
}

.nav__menu {
  line-height: 21px;
  /* font-weight: 700; */
  text-transform: uppercase;
}
.nav__menu-item {
  display: inline-block;
  position: relative;
  margin: 5px;
}
.nav-link {
  color: #000000 !important;
}
.nav__menu-item:hover {
  background-color: #333;
  color: #fff !important;
}
.nav__menu-item:hover a {
  background-color: #333;
  color: #fff !important;
}
.nav__menu-item a.active {
  font-weight: bold;
}

.nav__menu-item:hover .nav__submenu {
  display: block;
}
.nav__submenu {
  font-weight: 300;
  text-transform: none;
  display: none;
  position: absolute;
  width: 12rem;
  z-index: 100;
  /* background-color: #00aeef; */
}
.nav__submenu-item:hover {
  background: rgba(0, 0, 0, 0.1) !important;
}
.update-z-index {
  z-index: 10000 !important;
}

/* new hover css */

/* Add responsiveness - on screens less than 580px wide, display the navbar vertically instead of horizontally */
@media screen and (max-width: 580px) {
  #navbar {
    padding: 1.25rem 0.625rem !important; /* Use !important to make sure that JavaScript doesn't override the padding on small screens */
  }
  #navbar a {
    float: none;
    display: block;
    text-align: left;
  }
  #navbar-right {
    float: none;
  }
}
.nav__submenu--right {
  left: 12rem;
  top: 0px;
}
