.getquote-container {
  width: 700px;
}
.quote-modal {
  max-width: 960px;
}
.container-getquote h1 {
  text-align: left;
  margin-bottom: 30px;
}
.quote-modal table td button.secondary {
  background: none;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 100px;
  height: 30px;
  width: 30px;
  padding: 10px;
  line-height: 0;
}
.getquote-input-flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  width: 700px;
}

.getquote-contact-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
}

.getquote-radio-input {
  margin-right: 5px;
}

.getquote-input-error-group {
  margin-top: 5px;
  margin-bottom: 5px;
}

.getquote-input {
  width: 340px;
  height: 56px;
  letter-spacing: 0.075em;
  color: #777;
  font-size: 15px;
  padding: 16px 25px;
  border: 1px solid #e1e1e1;
  outline: none;
}

.getquote-add-product-button {
  width: 150px;
  background-color: #999;
  font-size: 15px;
  padding: 13px 20px;
  border: 1px solid #e1e1e1;
  outline: none;
  border-radius: 0;
  color: white;
}

.getquote-input-error {
  width: 340px;
  height: 56px;
  letter-spacing: 0.075em;
  color: #777;
  font-size: 15px;
  padding: 16px 25px;
  border: 1px solid #db5461;
  outline: none;
}

.getquote-input-quantity {
  width: 100px;
  height: 56px;
  letter-spacing: 0.075em;
  color: #777;
  font-size: 15px;
  padding: 16px 25px;
  border: 1px solid #e1e1e1;
  outline: none;
}

.getquote-input-quantity-error {
  width: 100px;
  height: 56px;
  letter-spacing: 0.075em;
  color: #777;
  font-size: 15px;
  padding: 16px 25px;
  border: 1px solid #db5461;
  outline: none;
}

.message-container-getquote {
  width: 100%;
}

.message-textarea-getquote {
  width: 100%;
  height: 230px;
}

.invalid-feedback-getquote {
  font-size: 12px;
  text-align: left;
  color: #db5461;
}

.row {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .getquote-input-flex-container {
    flex-direction: column;
  }

  .quote-modal {
    max-width: 400px;
  }

  .message-textarea-getquote {
    width: 340px;
  }
}
